import Vue from 'vue'
import App from './App.vue'
import { Dialog } from 'vant';
import router from './router'
import store from './store'

import VueI18n from 'vue-i18n'
import messages from '@/utils/i18n'

// vant多语言
import locales from './utils/vantLocal.js'
Vue.prototype.$Local = locales
locales(localStorage.getItem("lang") || 'en')

//配置vant
import './plugin/vant'

Vue.use(VueI18n);
Vue.use(Dialog);
// 多语言
const i18n = new VueI18n({
	locale: localStorage.getItem("lang") || 'en',
	messages
})

// 挂载api
import $api from '@/utils/api'
Vue.prototype.$api = $api

// 自定义导航栏
import NavigationTitle from '@/components/NavigationTitle.vue'
Vue.component('NavigationTitle', NavigationTitle)

// 使用 vue-meta
import Meta from "vue-meta";
Vue.use(Meta);

//路由拦截
router.beforeEach((to, from, next) => {
	// if(!to.meta.title) {
	// 	document.title = `Bless Jewellery`;
	// 	document.querySelector('meta[name="Keywords"]').setAttribute('content', `Bless Jewellery`);
	// 	document.querySelector('meta[name="description"]').setAttribute('content', `Bless Jewellery`);
	// }
	next()
})

Vue.config.productionTip = false

new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app')
